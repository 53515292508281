import React from "react";
import "./Header.scss";

const Header = () => (
    <div className="header">
        <h2>Tophandour Text Zone</h2>
        <p>This is a placeholder for a game I'm working on, title not yet decided. Currently all you can do is type North or South and get a message back.
            This is the same as the last build except it's refactored to use Duck Typing.
        </p>
    </div>
);

export default Header;