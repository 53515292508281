import React, { Component } from "react";
import "./Message.scss";

class Message extends Component {
  constructor(props) {
    super(props);
    let temp = JSON.parse(this.props.message);
    this.state = {
      message: temp
    };
  }

  render() {
    let bodyText = this.state.message.body;
    // bodyText = bodyText.replace("\n", "<br />");
    return <div className="Message">{bodyText}</div>;
  }
}

export default Message;