import React, { Component } from "react";
import "./MessageHistory.scss";
import Message from '../Message/Message';

class MessageHistory extends Component {
  render() {
    console.log(this.props.messageHistory);
    const messages = this.props.messageHistory.map(msg => <Message message={msg.data} />);
  
    return (
      <div className='MessageHistory'>
        <h2>Message History</h2>
        <div className='MessageHistoryMessages' id="MessageHistoryMessages">
          {messages}
          <div className="MessageHistoryAnchor" id="MessageHistoryAnchor"></div>
        </div>
      </div>
    );
  };
}

export default MessageHistory;