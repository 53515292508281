const getBackendUrl = () => {
  let backendUrl;
  switch(process.env.NODE_ENV) {
    case 'production':
      backendUrl = "wss://tophandourtextzone-fpaqv6aeoa-ue.a.run.app/ws";
      break;
    case 'development':
    default:
      backendUrl = "ws://192.168.1.3:8080/ws";
  }

  return backendUrl;
}
var socket = new WebSocket(getBackendUrl());

let connect = cb => {
  console.log("Attempting Connection...");

  socket.onopen = () => {
    console.log("Successfully Connected");
  };

  socket.onmessage = msg => {
    console.log(msg);
    cb(msg);
  };

  socket.onclose = event => {
    console.log("Socket Closed Connection: ", event);
  };

  socket.onerror = error => {
    console.log("Socket Error: ", error);
  };
};

let sendMsg = msg => {
  console.log("sending msg: ", msg);
  socket.send(msg);
};

export { connect, sendMsg };