import React, { Component } from "react";
import "./GameCommandInput.scss";

class GameCommandInput extends Component {
  render() {
    return (
      <div className="GameCommandInputDiv">
        <input className="GameCommandInput" placeholder="Input Command Here" onKeyDown={this.props.send} />
      </div>
    );
  }
}

export default GameCommandInput;